import { defineStore } from "pinia";

export const useCartStore = defineStore("cart", {
    state: () => ({
        isAddingToCart: false,
        isAddingToWishList: false,
    }),
    actions: {
        setAddingToCart(status) {
            this.isAddingToCart = status;
        },
        setAddingToWishList(status) {
            this.isAddingToWishList = status;
        },
    },
});