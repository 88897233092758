import {GetMapping, PostMapping, RequestMapping2Class, RequestParam} from "@/http";
import AddToCartDto from "@/service/model/cart/AddToCartDto";
import BatchAddToCartDto from "@/service/model/cart/BatchAddToCartDto";
import ShoppingCartResponseDto from "@/service/model/cart/ShoppingCartResponseDto";
import FreightRebateInfoDto from "./model/cart/FreightRebateInfoDto";
import {QueryMap} from "../http";
import ShoppingCartRequestDto from "./model/cart/ShoppingCartRequestDto";
import AddionalFeeInfoDto from "./model/cart/AddionalFeeInfoDto";
import ShoppingCartStatisticsDto from "./model/cart/ShoppingCartStatisticsDto";
import UpdateCartQtyDto from "@/service/model/cart/UpdateCartQtyDto";
import DddToCartByCodeDto from "./model/cart/DddToCartByCodeDto";
import TryCheckOutDto from "./model/cart/TryCheckOutDto";


@RequestMapping2Class("/cart")
class CartController {
    @PostMapping("/addToCart")
    addToCart(@RequestParam("productId") productId: number, @RequestParam("qty") qty: number):Promise<AddToCartDto>{
        throw 1;
    }
    @PostMapping("/batchAddToCart")
    batchAddToCart(@RequestParam("productIds") productIds: number[]):Promise<BatchAddToCartDto>{
        throw 1;
    }
    @PostMapping("/buyNow")
    buyNow(@RequestParam("productId") productId: number, @RequestParam("qty") qty: number):Promise<AddToCartDto>{
        throw 1;
    }

    @PostMapping("/changeCheck")
    changeCheck(@RequestParam("shoppingCartId") shoppingCartId: number, @RequestParam("isChecked") isChecked: boolean):Promise<number[]>{
        throw 1;
    }

    @GetMapping("/shoppingCart")
    shoppingCart(@QueryMap list: ShoppingCartRequestDto):Promise<ShoppingCartResponseDto>{throw 1;}

    @GetMapping("/freightRebateInfo")
    freightRebateInfo(@RequestParam("totalWeight") totalWeight: number,@RequestParam("totalVolume") totalVolume: number,
                      @RequestParam("orderTotalAmount") orderTotalAmount: number,@RequestParam("noVolumeProductAmount") noVolumeProductAmount: number
                      ,@RequestParam("freightRebateId") freightRebateId: number):Promise<FreightRebateInfoDto>{throw 1;}

    @GetMapping("/getAddionalFeeInfo")
    getAddionalFeeInfo(@RequestParam("cartItemId") cartItemId:number):Promise<AddionalFeeInfoDto>{throw 1;}


    @GetMapping("/getPackingFee")
    getPackingFee(@RequestParam("type") type:number,@RequestParam("productCode")productCode:string,@RequestParam("category2")category2:number, @RequestParam("category3")category3:number
                  ,@RequestParam("packNum")packNum:number,@RequestParam("packQty")packQty:number,@RequestParam("totalPackNum")totalPackNum:number,@RequestParam("unitEn")unitEn:string):Promise<number>{throw 1;}

    @PostMapping("/addAddionalFee")
    addAddionalFee(@RequestParam("cartItemId") cartItemId:number,@RequestParam("packType")packType:number,@RequestParam("typeStr")typeStr:string, @RequestParam("msg")msg:string
        ,@RequestParam("msg2")msg2:string,@RequestParam("sure")sure:boolean,@RequestParam("packNum")packNum:number,@RequestParam("packNumUnit")packNumUnit:string
        ,@RequestParam("packFee")packFee:number,@RequestParam("packQty")packQty:number,@RequestParam("totalFee")totalFee:number):Promise<number>{throw 1;}

    @GetMapping("/deleteCartProduct")
    deleteCartProduct(@RequestParam('shoppingCartId')shoppingCartId:number):void{throw 1}

    @PostMapping("/deleteCartProducts")
    deleteCartProducts():Promise<void>{throw 1}

    @PostMapping("/addToCartByCode")
    addToCartByCode(@RequestParam('codes')codes:string):Promise<DddToCartByCodeDto>{throw 1}

    @GetMapping("/productProcessingTime")
    productProcessingTime():Promise<number>{throw 1;}

    @GetMapping("/updateCartQty")
    updateCartQty(@RequestParam('cartItemId')cartItemId:number, @RequestParam('qty')qty:number):Promise<UpdateCartQtyDto>{throw 1}

    @GetMapping("/movetoWishList")
    movetoWishList(@RequestParam('productId')productId:number,@RequestParam('shoppingCartId')shoppingCartId:number,@RequestParam('wishListDirId')wishListDirId:number):Promise<any>{throw 1}

    @GetMapping("/batchCartMovetoWishList")
    batchCartMovetoWishList(@RequestParam('productId')productId:string,@RequestParam('wishListDirId')wishListDirId:number):Promise<any>{throw 1}

    @GetMapping("/useCouponCash")
    useCouponCash(@RequestParam('couponCode')couponCode:string,@RequestParam('useCash')useCash:boolean):Promise<void>{throw 1}

    @GetMapping("/notShowUnDisplayProducts")
    notShowUnDisplayProducts():Promise<void>{throw 1}

    @PostMapping("/tryCheckOut")
    tryCheckOut():Promise<TryCheckOutDto>{throw 1}
}

export const cartController = new CartController;