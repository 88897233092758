<template>
  <div class="navigation hidden-sm hidden-md">
    <ul class="clearfix">
      <li class="">
        <a href="/">Home</a>
      </li>
      <li class="">
        <a href="/new-beads-wholesale/1.html">New<br>Arrivals</a>
      </li>
      <li class="">
        <a href="/best-seller.html">Best <br>Sellers</a>
      </li>

      <li class="BigSale">
        <a href="/mix-beads-wholesale/1.html">Mixed<br/>Products</a>
      </li>
      <li class="">
        <a href="/bontique/1.html" title="Boutique">Boutique</a>
      </li>

    </ul>
  </div>
</template>

<style scoped src="./nav.less" lang="less"></style>