import {inject, nextTick, onMounted, reactive, ref, toRefs} from 'vue';
import AddToCartDto from "@/service/model/cart/AddToCartDto";
import {cartController} from "@/service/CartController";
import popLogin from "@/components/common/popLogin";
import useUserStore from "@/store/user";
import {itegtm} from "@/utils/gtm";
import {getScreen, PhScreen} from "@/utils/media";
import { useCartStore } from "@/store/cart";
export default {
    name: "AddToCart",
    props: {
        pid: Number,
        qty: Number,
        dom: {},
        div: {},
        submitCallback: {
            type: Function // 确认事件
        },
        cancelCallback: {
            type: Function // 取消事件
        },
        successCallback: Function,
    },
    setup(props, content) {
        const store = useUserStore();
        const { isAddingToCart, setAddingToCart } = useCartStore();
        const fade = ref(false)
        const data = reactive({
            tipMsg: <string> '',
            item:"1 "+"item".translate(),
            unit:"1 "+"unit".translate()
        })
        const addToCart = async () => {
            if (isAddingToCart) {
                console.log("请求正在进行中，请稍候...");
                return;
            }
            setAddingToCart(true);
            // props.dom.classList.add('addCartLoading')
            try {
                const returnObj = await cartController.addToCart(props.pid, props.qty);
                // props.dom.classList.remove('addCartLoading')
                if (returnObj.needLogin) {
                    popLogin().then(addToCart, () => {}).catch(props.cancelCallback);
                } else {
                    if (returnObj.status) {
                        store.updateHeadCart({
                            qty: returnObj.qty,
                            price: returnObj.totalPrice
                        });
                        try {
                            if (!store.headerDto.simulatedLogin) {
                                let ga4ListName = "Other";
                                let ga4ListId = "O001";
                                let detailPattern = /p-(\d+)-(.*).html/ig;
                                let herf = window.location.href;
                                if (detailPattern.test(herf)) {
                                    ga4ListName = "Product";
                                }
                                itegtm.addToCart([props.pid], ga4ListName, ga4ListId, [props.qty]);
                            }
                        } catch (e) {
                            console.log("itegtm is not defined");
                        }
                    }

                    if (returnObj.qty > 1) {
                        data.item = returnObj.qty + " " + "items".translate();
                    }
                    if (returnObj.cartQty > 1) {
                        data.unit = returnObj.cartQty + " " + "units".translate();
                    }
                    switch (returnObj.message) {
                        case 'SHOW_ADD_SUCCESS_TIP':
                            data.tipMsg = 'addToCart.successTip'.translate(data.item, data.unit, returnObj.totalPrice.money());
                            break;
                        case 'SHOW_DELAY_TIP':
                            data.tipMsg = 'addToCart.delayTip'.translate(data.item, data.unit, returnObj.totalPrice.money());
                            break;
                        case 'SHOW_LARGE_PACKAGE_TIP':
                            data.tipMsg = 'addToCart.largePackageTip'.translate(data.item, data.unit, returnObj.totalPrice.money());
                            break;
                        case 'ERROR_SHOPPINGCART_PRODUCT_NOT_EXISTS':
                            data.tipMsg = 'addToCart.produceNotExistsError'.translate();
                            break;
                        case 'ERROR_SHOPPINGCART_PRODUCT_OUT_OF_STOCK':
                            //修改productQty的值
                            let productQtyInput = document.querySelector('input[name="productQty"]') as HTMLInputElement;
                            if(productQtyInput){
                                let qty = returnObj.allQty-returnObj.haveQty;
                                productQtyInput.value = (qty>0?qty:1).toString();
                                productQtyInput.dispatchEvent(new Event("input"));
                            }
                            data.tipMsg = 'addToCart.productOutOfStockError'.translate(returnObj.allQty, returnObj.haveQty);
                            break;
                        case 'ERROR_SHOPPINGCART_PRODUCT_NO_PRICE':
                            data.tipMsg = 'addToCart.productNoPriceError'.translate();
                            break;
                        default:
                            data.tipMsg = returnObj.message
                    }

                    props.submitCallback(returnObj);

                    // 自定义成功回调
                    if (props.successCallback && typeof props.successCallback === 'function') {
                        props.successCallback(returnObj);
                    }
                    // 设置位置
                    setPosition(props.dom, props.div)


                }
            } catch (error) {
                console.error("添加到购物车失败:", error);
            } finally {
                setAddingToCart(false);
                // 当元素渲染完毕立即过渡的动画不会触发
                setTimeout(() => {
                    fade.value = true;
                }, 0);
            }
        };

        onMounted(addToCart);

        // add to cart 弹窗位置
        const setPosition = async (clickWrap, popWrap) => {
            await nextTick()
            if(getScreen()!= PhScreen.PHONE && getScreen()!= PhScreen.PHONE_SMALL) {
                let {width, height, top, left} = clickWrap.getBoundingClientRect()
                popWrap.style.position = "absolute"
                popWrap.style.zIndex = 99
                popWrap.style.top = top  + window.scrollY - popWrap.clientHeight - 6 + "px"
                popWrap.style.left = left + window.scrollX - ((popWrap.clientWidth- clickWrap.clientWidth)/2) + "px"
            } else {
                popWrap.style.position = "fixed"
                popWrap.style.zIndex = 99
                popWrap.style.top = '50%'
                popWrap.style.left = '25%'
            }
        }


        return {
            fade,
            ...toRefs(data)
        }
    }
}