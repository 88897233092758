const en={
    "header.contactUs":"Contact Us",
    "header.shippingFee":"Shipping Fee",
    "header.orderTracking":"Order Tracking",
    "header.myAccount":"My Account",
    // footer
    "header.help":"Help",
    "header.cart":"Cart",
    "header.shop":"SHOP",
    "header.subscribe":"Subscribe to our mailing list",
    "header.jewelryBeads":"Jewelry Beads",
    "header.jewelryFindings":"Jewelry Findings",
    "header.beadingSupplies":"Beading Supplies",
    "header.stringingMaterials":"Stringing Materials",
    "header.jewelryWatches":"Jewelry & Watches",
    "header.hairFindings":"Hair Accessories & Findings",
    "header.sewingKnitting":"Sewing & Knitting",
    "header.homeGarden":"Office & Home & Garden",
    "header.information":"INFORMATION",
    "header.aboutUs":"About Us",
    "header.discountPolicy":"Discount Policy",
    "header.sitemap":"Sitemap",
    "header.paymentInfo":"Payment Info",
    "header.customerService":"CUSTOMER SERVICE",
    "header.shippingDelivery":"Shipping & Delivery",
    "header.privacyPolicy":"Privacy Policy",
    "header.returnPolicy":"Return Policy",
    "header.helpFaq":"Help & FAQ",
    "header.customsDuties":"Customs & Duties",
    "header.termsConditions":"Terms & Conditions",
    "header.latestLbeads":"LATEST FROM LBEADS",
    "header.latestCobeads":"LATEST FROM COBEADS",
    "header.joinUsSlogan":"Join us for news,updates,special deals,discounts and everything about beads.",
    "header.stayConnected":"STAY CONNECTED",
    "header.myFootPrint":"My Footprint",
    "header.signIn":"Sign In",
    "header.shopCart":"Shopping Cart",
    "header.newCustomer":"New Customer",
    "header.signOut":"Sign out",
    "header.cashAccount":"Cash Account",
    "header.myOrders":"My Orders",
    "header.wishList":"Wish List",
    "header.ticketCenter":"Ticket Center",
    "header.onlyForYou":"Only For You",
    "header.myCoupons":"My Coupons",
    "header.expireSoon": "Expire Soon",
    "header.myCash":"My Cash",
    "header.points":"Points",
    "header.myCart":"My Cart",
    "header.currency":"Currency",
    "footer.helpCenter":"Help Center",
    "footer.searchHelp":"Search the help pages",
    "footer.searchItems":"Does not search for items or products",
    "footer.askQuestions":"Most Frequently Asked Question",
    // 上传图片
    "header.searchByImage": "Search By Image",
    "header.searchLbeads": "Search lbeads with an image instead of text. Try dragging an image here.",
    "header.uploadImg": "Upload an image",
    "header.pasteImg": "Paste image URL",
    "header.uploadImgTip1": "The image must be in one of the following formats: .jpg, .png, .jpeg, .bmp, .gif, .webp, .tiff, .ppm",
    "header.uploadImgTip2": "The size of the image should be less than 5M!",
    "header.uploadImgTip3": "The image length and width must be greater than or equal 200 pixels and smaller than or equal to 1024pixes.",
    "header.uploadFile": "Upload File",
    // 导航
    "header.allCategories":"All Categories",
    "header.bestSellers": "Best Sellers",
    "header.newArrivals": "New Arrivals",
    "header.mixedProducts": "Mixed Products",
    "header.promotion": "Promotion",
    "header.bulkArticle": "Bulk Article",
    "header.smallBatch": "Small Batch",
    "header.smallLot": "Small Lot",
    "header.collections": "Collections",
    "header.trends": "Trends",
    "header.hot": "Weekly Hot",
    "header.clearance": "Clearance",
    "header.boutique": "Boutique",
    "header.designStudio": "Design Studio",
    "header.jewelryGallery": "Jewelry Gallery",
    "header.holidayCollection": "",
    // 手机
    "header.repackingService": "Repacking Service",
    "header.deliveryFee": "Delivery Fee",
    "header.changeCurrency": "Change Currency",
    "header.register":"Register",
    "header.newArri":"New<br/>Arrivals",
    "header.bestSeller":"Best <br>Sellers",
    "header.designStudi":"Design<br>Studio",
    "footer.privacy":"We and our partners use cookies and other technologies to enhance your experience, measure performance, and customize marketing strategies. For more details, please refer to our <a style='color:#fff;text-decoration: underline' href=\"/HelpCenter/HelpDetail?articleId=127&amp;categoryId=17 \">Privacy Policy</a>.",

    'login.email.exists':'It appears that you already have an account with us.',
    'login.email.empty': 'Please enter your e-mail address.',
    'login.email.notexists':'Please enter a correct email address.',
    'login.email.locked':'Your account has be locked.',
    'login.password.empty':'Please enter your password.',
    'login.validatecode.error': 'The characters you entered don\'t match what are shown in the image.',
    'login.validatecode.empty':'Please enter the characters as they are shown in the image.',
    'register.password.error':'The password is incorrect.',
    'register.email.exists.error':'An account already exists for this email address.',
    'register.oldpassword.empty':'Please enter your old password.',
    'register.lastname.empty': 'Please enter your last name.',
    'register.firstname.empty': 'Please enter your first name.',
    'register.password.type':"The password should consist with 6 - 20 characters (A-Z, a-z, 0-9 ，!\"#$%&'()*+,-./:;<=>?[\\]^_`{|}~ only).",
    'register.resetpassword':"It doesn't match your password. Please try again.",
    "account.password.error":"The current password is incorrect. Please reenter.",
    "register.password.forOrder":"for order over",
    "register.password.valid":"Valid for",
    "register.password.days":"Days",
    "register.password.terms":"Terms and Conditions",
    "register.password.forNewCustomers":"for New Customers",
    "register.password.conditions":"• Cobeads reserves the right to the ultimate interpretation of the above terms &amp; conditions.",
    "register.password.unavailable":"• <span style=\"color: #f94d5a;\">ONLY</span> for new customers, unavailable for repeated registration;",
    "register.password.orderCan":"• One order can be paid by only <span style=\"color: #f94d5a;\">ONE</span> coupon;",
    "register.password.eachCoupon":"• Each coupon can be used only <span style=\"color: #f94d5a;\">ONCE</span>;",
    "register.password.excluding":"• Coupon is <span style=\"color: #f94d5a;\">ONLY</span> for product cost, excluding shipping fee;",

    // 登录页
    'login.email':'Email',
    'login.password':'Password',
    'login.verificationCode':'Verification Code',
    'login.keepLogin':'keep me signed in on this computer unless I sign out',
    'login.forgetPass':'Forgotten password',
    'login.signIn':'Sign In',
    'login.title': 'cobeads.com Sign in / Register',
    'login.text':'If you already have a cobeads.com websites account, you can login with the account information.',
    'register.subscribeNewsletter':'I would like subscribe coBeads newsletter with the latest offers and news(up to 2 Emails per week)',
    "myaccount.confirmLb":"Are you sure to deactivating your account?\n" +
        "You will have no access to your cobeads.com account.",

    'register.money':"{0},<a target=\"_blank\" href=\"/HelpCenter/HelpDetail?articleId=139\" class=\"fontRed\"> click here</a> for details",
    'register.register':'Register',
    'register.selectEmail':'Please select email type',
    'register.weak':'Weak',
    'register.medium':'Medium',
    'register.strong':'Strong',
    'register.rePass':'Re-Password',
    'register.fisrtName':'First Name',
    'register.lastName':'Last Name',
    'register.saveCart':'Save products to shopping cart',
    'register.trackOrders':'Track delivery of your orders ',
    //  注册成功页面
    'registerSuccess.title':'Register Successfully',
    'registerSuccess.thankRegister':'Thanks for registering with cobeads.com',
    'registerSuccess.thankLbRegister':'Thanks for registering with lbeads.com',
    'registerSuccess.memberId': 'Your Member ID:',
    'registerSuccess.offerCoupon':'{0}.Have a nice shopping at cobeads!',
    'registerSuccess.couponCode': 'Coupon Code',
    'registerSuccess.parValue': 'Par Value',
    'registerSuccess.usingAmount': 'Min. Using <br>Amount:',
    'registerSuccess.periodValidity': 'Period of Validity',
    'registerSuccess.alreadyRegister':'We found that you have already registered by the email:<strong>{0}</strong> through which we\'ve offered coupon as new customer, so no coupon for this account,because the coupon is only for new customer, not for every new account. Thanks for your understanding.',
    'registerSuccess.nowYou': 'Now You can:',
    'registerSuccess.returnTo': 'Return to',
    'registerSuccess.goTo': 'Go to',
    'registerSuccess.previousPage': 'Previous Page',
    'registerSuccess.homePage':'Home Page',


    // deliveryFee
    'deliveryFee.chooseCountry':'Please choose your Country/Region then you can get the shipping cost.',
    'deliveryFee.countryRegion':'Country/Region:',
    'deliveryFee.selectCountry':'Please select your Country/Region.',
    'deliveryFee.displayMode':'Display Mode:',
    'deliveryFee.detailedModel':'Detailed Model',
    'deliveryFee.conciseModel':'Concise Model',
    'deliveryFee.shippingMethod':'Shipping Method:',
    'deliveryFee.workingDays': 'working days',

    'deliveryFee.weight': 'Weight',
    'deliveryFee.price': 'Price(USD)',

    // 加入购物车

    'addToCart.successTip' : "Add to cart successfully <p class='line'>{0} / {1} total: <b class='red'>{2}</b></p>",
    'addToCart.delayTip' : "Add to cart successfully  <p class='line'>Preparation time will be extanded for <b>3-5</b> days for purchase quantity exceeds inventory.</p><p class='line'>{0} / {1} total: <b class='red'>{2}</b></p>",
    'addToCart.largePackageTip' : "Add to cart successfully <p class='line'>Due to your purchasing quatity is large, please re-choose large batch item code to buy to share the competitive price</p><p class='line'>{0} / {1} total: <b style='color:#AF0F1E;'>{2}</b></p>",
    'addToCart.produceNotExistsError' : "This item has been off shelf.",
    'addToCart.productOutOfStockError' : "Sorry, product only have {0} units in stock, and you already have {1} units in your shopping cart.",
    'addToCart.unitsInStock' : "Sorry, product only have {0} units in stock.",
    'batchAddToCart.outOfStockTip': "The Product out of stock!",
    'addToCart.notFindProductTip': "Could not find product",
    'addToCart.addFaildTip': "Faild to add product to cart.",
    'batchAddToCart.productNoPriceError': "Something is wrong with the item price. Please contact us to solve it.",
    'addToCart.invalidNumber' : "Sorry that the number you input is invalid, please enter a valid positive integer.",
    'addToCart.quantityMustUp': "Purchase quantity must up to start quantity.",
    'addToCart.viewCart':'View Cart',
    'addToCart.close':'Close',

    "helpCenter.helpAsk.tips":"Tips",
    "helpCenter.helpAsk.serviceOnDuty":`{0} Customer Service teams are available at 9: 00AM-5: 00PM, from Monday to Saturday.
          <a href="http://www.thetimezoneconverter.com/" target="_blank">Click here</a> to convert it to your local time. Now, it is
          <strong>{1}(GMT +8)</strong>. Please leave your questions; we will give you the reply at our earliest convenience.`,
    "helpCenter.helpAsk.ticket":"Ticket",
    "helpCenter.helpAsk.successful":"Submission Successful!",
    "helpCenter.helpAsk.yourEmail":"Your E-mail",
    "helpCenter.helpAsk.emailValid":"We will reply to you by email, please make sure the email address is valid.",
    "helpCenter.helpAsk.content":"Content",
    "helpCenter.helpAsk.verificationCode":"Verification Code",
    "helpCenter.helpAsk.attachment":"( .gif | .png | .jpeg | .jpg | .doc | .bmp | .xls | .rar | .zip | .xlsx | .pdf ) The size of the attachment should be less than 2M !",
    "helpCenter.helpAsk.submit":"Submit",
    "helpCenter.helpAsk.reset":"Reset",
    "helpCenter.helpAsk.email":"Email",
    "helpCenter.helpAsk.address":"Address",
    "helpCenter.helpAsk.phone":"Phone",
    "helpCenter.helpAsk.views":"Views",
    "helpCenter.helpAsk.most":"Most viewed articles",
    "helpCenter.helpAsk.lastUpdated":"Last Updated",
    "helpCenter.helpAsk.latestArticles":"Latest articles",
    "helpCenter.helpAsk.term":"Term & Conditions",
    "helpCenter.helpAsk.contentMsg":"You must provide content.",
    "helpCenter.helpAsk.fileSizeMsg":"The size of the attachment should be less than 2M !",
    "helpCenter.helpAsk.fileTypeMsg":"We support .gif|.png|.jpeg|.jpg|.doc|.bmp|.xls|.rar|.zip|.xlsx|.pdf",

    "helpCenter.helpDetail.previous":"Previous",
    "helpCenter.helpDetail.next":"Next",

    "helpCenter.back":"Back",
    "helpCenter":"Help Center",
    "helpCenter.leftTree.contactUs":"Contact Us",
    "helpCenter.leftTree.haveQuestion":"Have a question",
    "helpCenter.leftTree.weCanHelp":"We can help",
    "helpCenter.leftTree.askQuestion":"Ask Question",

    "helpCenter.helpCategory.most":"Most Frequently Asked Questions",
    "helpCenter.helpCategory.more":"More",

    "helpCenter.helpSearch.result":'{0} - {1} of {2} Results for <b class="fontRed">"{3}"</b>',
    "helpCenter.helpSearch.noResult":'Not Found Results for  <b class="fontRed">"{0}"</b>',

    "helpCenter.helpSearch.searchHelpPages":'Search the help pages',
    "helpCenter.helpSearch.notSearchProducts":'Does not search for items or products',
    "helpCenter.helpSearch.search":'Search',


    //支付中心
    "center.orderDetails":"Order Details",
    "center.orderSummary":"Order Summary",
    "center.productCost":"Product Cost",
    "center.shippingHandlingFee":"Shipping & Handling Fee",
    "center.addtionalFee":"Addtional Fee",
    "center.couponRedemption":"Coupon Redemption",
    "center.replacementRedemption":"Replacement Redemption",
    "center.specialOffer":"Special Offer",
    "center.orderTotal":"Order Total",
    "center.totalPaid":"Total Paid",
    "center.helpInformation":"Help Information",
    "center.stillNeedtoPay":"Still Need to Pay",
    "center.paymentDetails":"Payment Details",

    //支付完成页
    "completed.WelcomeToTheLuckyDraw":"Welcome to the lucky draw!",
    "completed.congratualations":"Congratualations",
    "completed.got2Cash":"You have got a 2$ cash",
    "completed.got8Coupon":"You have got a $8 coupon",
    "completed.got3Off":"You have got a 3% off <br>discount coupon",
    "completed.got30Coupon":"You have got a $30 coupon",
    "completed.couponValidTime30":"Valid Time: 30 days after received",
    "completed.couponCode":"Coupon Code",
    "completed.tryAgain":"Let's try again",
    "completed.goForIt":"Go For it",
    "completed.thankParticipation":"Thank you for your participation",
    "completed.hopeYouWinNextTime":"Hope you will win prize next time",
    "completed.ruleDescription":"Rule Description",
    "completed.pleaseParticipate":"Please participate in the prize draw at this page, otherwise you will not find it anymore. Thank you.",
    "completed.anotherChance":"You've got only one chance to draw the prize. But if you draw the \"Try again\", you will get another chance to draw the prize.",
    "completed.drawInAccount":"If you draw the \"$8 coupon\", \"3% off discount coupon\", \"$2 cash\" and \"$30 Coupon\", the prize will be in your account immediately. You can use it for your next order payment.",
    "completed.thankYouForShopping":"Thank you for shopping with us",
    "completed.registeringEnjoyService":"<a style='color: #f94d5a;text-decoration: underline;' href=\"/Customer/SignIn\">Registering as {0} member</a> will enjoy better service and various preferential policies from now on.",
    "completed.offerPackingDividing":"{0} can offer Packing and Dividing Jewelry Findings,OEM processing and other services based on your requirements which can save much cost for you. Want to know the details? Please <a href=\"/HelpCenter/HelpDetail?articleId=11&categoryId=4\">click here</a>.",
    //支付方式
    "payMethod.neverAskChangeBeneficiary":"Please make sure your payment is paid to the above account, {0} will never ask customer to change the beneficiary account by email or any other ways.",
    "payMethod.noteCheckBeneficiaryName":"Please kindly note Check Beneficiary's name is \"<b> {0}</b>\", not {1}.",
    "paymentFailedTip":"Payment Failed. Code:{0}; respMsg:{1}",


    "myaccount.myAccount":"My Account",
    "myaccount.allOrders":"All Orders",
    "myaccount.pending":"Pending",
    "myaccount.preparing":"Preparing",
    "myaccount.prepared":"Prepared",
    "myaccount.packing":"Packing",
    "myaccount.sent":"Sent",
    "myaccount.finished":"Finished",
    "myaccount.cancelled":"Cancelled",
    "myaccount.paymentHistory":"Payment History",
    "myaccount.purchaseHistory":"Purchase History",
    "myaccount.recentItems":"Recently Purchased Items",
    "myaccount.cancelledItems":"Cancelled Items",
    "myaccount.personalization":"Personalization",
    "myaccount.accountSetting":"Account Setting",
    "myaccount.myWishList":"My Wish List",
    "myaccount.myShoppingCart":"My Shopping Cart",
    "myaccount.newsletter":"Newsletter",
    "myaccount.myReviews":"My Reviews",
    "myaccount.myFootprint":"My Footprint",
    "myaccount.ticketCenter":"Ticket Center",
    "myaccount.allTickets":"All Tickets",
    "myaccount.unreadTickets":"Unread Tickets",
    "myaccount.submitTicket":"Submit a Ticket",
    "myaccount.expireSoon":"Expire Soon",
    "myaccount.complaint":"Complaint",
    "myaccount.cashCoupon":"Cash & Coupon",
    "myaccount.cashAccount":"Cash Account",
    "myaccount.coupon":"Coupon",
    "myaccount.points":"Points",
    "myaccount.specialBargains": "Special Bargains For You",
    "myaccount.shippingDiscount": "Shipping Discount Only for You",
    "myaccount.onlyForYou":"Only For You",
    "myaccount.submit":"Submit",


    // newsletter
    "newsletter.notice":"Notice",
    "newsletter.privacy":"We value your privacy and never share your personal information or Email address with any third party company or organization.",
    "newsletter.sendEmail":"We will only send you 2 Emails weekly",
    "newsletter.subscribeNewsletter":"Please click the following button to subscribe our newsletter with the latest {0} news, offer, discount and new products.",
    "newsletter.regularNewsletter":"Regular Newsletter –Weekly new arrivals, hot sellers or promotion information.",
    "newsletter.couponNewsletter":"Coupon Newsletter - Keep you reminded about the coupon expiry date and new coupons available.",

    // accountSetting
    "accountSetting.deactivateAccount":"Deactivate Account",
    "accountSetting.myProfile":"My Profile",
    "accountSetting.changePassword":"Change Password",
    "accountSetting.changeEmail":"Change Email Address",
    "accountSetting.firstName":"First Name",
    "accountSetting.lastName":"Last Name",
    "accountSetting.passStrong":"Your password strength is too weak, Please reset your password.",
    "accountSetting.oldPass":"Old Password",
    "accountSetting.newPass":"New Password",
    "accountSetting.weak":"Weak",
    "accountSetting.medium":"Medium",
    "accountSetting.strong":"Strong",
    "accountSetting.confirmPass":"Confirm New Password",
    "accountSetting.newEmail":"New Email",
    "accountSetting.verificationCode":"Verification Code",
    'accountSetting.emailEdit.msg':'Your email address has been modified successfully.',
    'accountSetting.passEdit.msg':'Your password has been modified successfully.',
    'accountSetting.nameEdit.msg':'Your profile has been modified successfully.',

    // ticket
    "ticketCenter.allTickets":"All Tickets",
    "ticketCenter.unReadTickets":"Unread Tickets",
    "ticketCenter.selectCategory":"Select Category",
    "ticketCenter.advancedSearch":"Advanced Search",
    "ticketCenter.keyword":"Ticket Keyword",
    "ticketCenter.current": "Current",
    "ticketCenter.before": "Before",
    "ticketCenter.subject": "Subject",
    "ticketCenter.lastUpdate": "Last Update",
    "ticketCenter.attachment":"Attachment:",
    "ticketDetail.you":"You:",
    "ticketCenter.date":"Date:",
    "ticketCenter.reset":"Reset",
    "ticketCenter.noTicket":"No ticket was found.",
    "ticketCenter.subSuccessful":"Submission Successful!",

    // submit a ticket
    "ticketCenter.submitTicket":"Submit a Ticket",
    "ticketCenter.createTicket":"Create a new ticket",
    "ticketCenter.category":"Category:",
    "ticketCenter.createTime":"Create time:",
    "ticketCenter.content": "Content:",
    "ticketMsg.category":"Please select a category.",
    "ticketMsg.subject":"You must provide a subject.",
    "ticketMsg.content":"You must provide content.",
    "ticket.uploadImg":"( .gif | .png | .jpeg | .jpg | .doc | .bmp | .xls | .rar | .zip | .xlsx | .pdf )\n" +
        "                  The size of the attachment should be less than 2M ! ",

    "ticket.complaintNoticeHead":"Complain The Customer Service Staff" ,
    "ticket.complaintNoticeBody":"(Your complaint will be sent directly to our customer service manager, your exclusive customer service will not be able to see it.",


    // ticket detail
    "ticketDetail.ticketDetails":"Ticket Details",
    "ticketEdit.notice":"Notice:",
    "ticketEdit.complaint": "Complaint",
    "ticketEdit.complaintNotice":"Please if you are not satisfied with your client manager or our customer service, just submit a complaint. We have special team to deal with your complaint, and will reply to you at the earliest time",

    // coupon

    "coupon.title":"Coupon",
    "coupon.Notice":"Notice:",
    "coupon.explain":"You can use the active coupon to pay for the product cost when you check out. But please be noted that each coupon can be used for only one time , and also one order can be paid by only one coupon.",
    "coupon.Please":"Please",
    "coupon.addedSuccessFully":"Added successfully !",
    "coupon.CodeEnter":"Sorry, please enter a coupon code !",
    "coupon.codeAdded":"Sorry, you have already added this coupon !",
    "coupon.codeIncorrect":"Sorry, the coupon code is incorrect !",
    "coupon.codeEnd":"Sorry, the coupon activity has ended !",
    "coupon.codeCollected":"Usage Limit of this Coupon Code Has Been Reached !",
    "coupon.couponCode":"Coupon Code",
    "coupon.orderNo":"Order No.",
    "coupon.parValue":"Par Value",
    "coupon.minUsingAmount":"Min. Using Amount",
    "coupon.status":"Status",
    "coupon.periodOfValidity":"Period of Validity",
    "coupon.used":"Used",
    "coupon.expired":"Expired",
    "coupon.active":"Active",
    "coupon.inactive":"Inactive",
    "coupon.noCoupon":"There is no any coupon.",

    // points
    "points.title":"Points",
    "points.Notice":"Notice:",
    "points.explain":"Points can be applied toward to orders, and please convert them into coupon here first. In the steps of\n" +"payment, you can use coupon to pay for your order. Please be kindly noted that one order can be paid by one\n" + " coupon.",
    "points.howToEarnPoints":"How to earn points?",
    "points.Tips":"Tips: Ensure to enter a multiple of 5 scores",
    "points.insufficient":"Insufficient Points",
    "points.submitted":"Coupon has been presented, please check",
    "points.Myaccount":"My account",
    "points.Cash":"Cash",

    "points.parValue":"ParValue:",
    "points.minimumUsingAmount":"Minimum Using Amount: Goods Value ",
    "points.expiredDate":"Expired Date:",
    "points.clickHere":"Click here",
    "points.cashCoupon":" to know how to use Cash Coupon",
    "points.date":"Date",
    "points.income":"Income",
    "points.payout":"Payout",
    "points.description":"Description",

    // paymentHistory
    "paymentHistory.paymentHistory":"Payment History",
    "paymentHistory.advancedSearch":"Advanced Search",
    "paymentHistory.paymentMethod":"Payment Method",
    "paymentHistory.all":"All",
    "paymentHistory.orderNo":"Order No",
    "paymentHistory.date":"Date",
    "paymentHistory.reset":"Reset",
    "paymentHistory.paidAmount":"Paid Amount",
    "paymentHistory.amountAppliedOrder":"Amount Applied to Order",
    "paymentHistory.thereNoPaymentHistory":"There is no any payment history",


    "myaccount.myOrder":"My Orders",
    "myaccount.myOrder.itemCodeOrName":"Item Code/Name",
    "myaccount.myOrder.item":"Item",
    "myaccount.myOrder.reviews":"Reviews",
    "myaccount.myOrder.awaitingPayment":"Awaiting Payment",
    "myaccount.myOrder.needToSelectReplacement":"Need to Select Replacement",
    "myaccount.myOrder.yourOrderHasBeenCancelled":"Your order {0} has been cancelled",
    "myaccount.myOrder.orderNotice":"Order Notice",
    "myaccount.myOrder.thereIsNotAnyOrder":"There is not any order",
    "myaccount.myOrder.cashPayment":"Cash Payment",
    "myaccount.myOrder.readyRate":"Ready Rate",
    "myaccount.myOrder.allOrders":"All Orders",
    "myaccount.myOrder.confirmOrderReceived":"Confirm Order Received",
    "myaccount.myOrder.review":"Review",
    "myaccount.myOrder.oKIKnow":"OK, I Know",
    "myaccount.myOrder.close":"Close",
    "myaccount.myOrder.trackPackage1":"Track Package1",
    "myaccount.myOrder.trackOrder":"Track Order",
    "myaccount.myOrder.inTransitToTheShippingAgent(takes 1-2 days)":"In transit to the shipping agent (takes 1-2 days)",
    "myaccount.myOrder.trackPackage2":"Track Package2",
    "myaccount.myOrder.realInvoice":"Real Invoice",
    "myaccount.myOrder.customInvoice":"Custom Invoice",
    "myaccount.myOrder.makePayment":"Make Payment",
    "myaccount.myOrder.cancel":"Cancel",
    "myaccount.myOrder.reOrder":"Re-Order",
    "myaccount.myOrder.submitATicket":"Submit a Ticket",
    "myaccount.myOrder.viewOrder":"View Order",
    "myaccount.myOrder.orderReviewPrint": "Write a review for this order, you will get <font color=\"red\">{0}</font> cash points.",
    "myaccount.myOrder.notRead": "Sorry, the following orders are unready temporarily. You can click \"View Order\"to know the details.",
    "myaccount.myOrder.submitSuccess": "The request of combing delivery  has submitted successfully!",
    "myaccount.myOrder.orderDate": "Order Date",
    "myaccount.myOrder.orderStatus": "Order Status",
    "myaccount.myOrder.orderNumber": "Order Number",
    "myaccount.myOrder.twoOrders":"Sorry! Please select at least two orders!",
    "myaccount.myOrder.Confirm":"Confirm",
    "myaccount.myOrder.combine":"Do you really want to combine shipping?",
    "myaccount.myOrder.cannotCombine":"Sorry, the order with different shipping methods cannot be combined.",
    "myaccount.myOrder.shippedTogether":"Sorry, orders with different shipping address can not be shipped together!",
    "myaccount.myOrder.canceledIt":"In order to improve our services to you, please tell us why you canceled it:",
    "myaccount.myOrder.reason":"Please select your reason",
    "myaccount.myOrder.characters":"Please do not exceed 200 characters",
    "myaccount.myOrder.anyMore":"I don't want it any more",
    "myaccount.myOrder.FalseOrder":"False or repetitive order",
    "myaccount.myOrder.High":"High price of the item",
    "myaccount.myOrder.cost":"High delivery cost",
    "myaccount.myOrder.changing":"Delivery address or method needs changing",
    "myaccount.myOrder.Paymentfailed":"Payment failed",
    "myaccount.myOrder.ItemExchange":"Item exchange or new item adding",
    "myaccount.myOrder.Other":"Other:",
    "myaccount.myOrder.Dispatch":"Estimate Dispatch Date",
    "myaccount.myOrder.Package1":"Package1",
    "myaccount.myOrder.Replacement":"Replacement",
    "myaccount.myOrder.OrderNo":"Order No",
    "myaccount.myOrder.Status":"Status",
    "myaccount.myOrder.OrderTotal":"Order Total",
    "myaccount.myOrder.TotalPaid":"Total Paid",
    "myaccount.myOrder.toCustoms":"Value Declared to Customs",
    "myaccount.myOrder.Action":"Action",
    "myaccount.myOrder.check":"Check",
    "myaccount.myOrder.to":"To",
    "myaccount.myOrder.package2":"Package2",
    "myaccount.myOrder.yes":"Yes",
    "myaccount.myOrder.no":"no",
    "myaccount.myFootprint.category":"Category",
    "myaccount.myFootprint.all":"--All--",
    "myaccount.myFootprint.allCate":"--All Categories--",
    "myaccount.myOrder.received":"Can we change your order status to received?",
    "myaccount.myOrder.confirm":"please confirm if you want to change the package status to \"Signed for\"",
    "myaccount.myOrder.wantTo":"Do you want to download real invoice of all combined orders?",
    "myaccount.myOrder.Rate":"Ready Rate:100%",
    "myaccount.myOrder.tryAgain":"This order can't be cancelled. You can refresh the page and try again.\r\nIf any need, please contact Customer Service.",

    "myaccount.addressBook":"Address Book",
    "myaccount.addressBook.notice":"Notice",
    "myaccount.addressBook.noticeText":"Credit card companies require that your billing address on an order be the same as the billing address registered with them.",
    "myaccount.addressBook.address":"Address",
    "myaccount.addressBook.defaultShippingAddress":"Default Shipping Address",
    "myaccount.addressBook.defaultBillingAddress":"Default Billing Address",
    "myaccount.addressBook.enterNewAddress":"Enter a new address",

    "myaccount.addressBook.companyName":"Company name",
    "myaccount.addressBook.phoneNumber":"Phone number",
    "myaccount.addressBook.addressLine1":"Address line1",
    "myaccount.addressBook.addressLine2":"Address line2",
    "myaccount.addressBook.country":"Country/Region",
    "myaccount.addressBook.state":"State/Province",
    "myaccount.addressBook.city":"City/Town",
    "myaccount.addressBook.zip":"ZIP/Postal code",
    "myaccount.addressBook.mobile":"Mobile",
    "myaccount.addressBook.acceptMessage":"I want to receive message about order status",
    "myaccount.addressBook.saveShippingAddress":"Save as my Shipping Address",
    "myaccount.addressBook.saveBillingAddress":"Save as my Billing Address",

    "myaccount.addressBook.enterEnglish":"Please enter English if possible..",
    "myaccount.addressBook.companyNameMsg":"Please enter your Company name with no more than 35 characters.",
    "myaccount.addressBook.fullNameMsg":"The total number of characters in First name and Last name cannot exceed 34 characters.",
    "myaccount.addressBook.phoneMsg":'Please enter your Phone Number with no more than 30 characters.',
    "myaccount.addressBook.streetMsg":"Please enter your Address with no more than 100 characters.",
    "myaccount.addressBook.countryMsg":"Please select your Country/Region.",
    "myaccount.addressBook.cityMsg":"Please enter your city/town with no more than 30 characters.",
    "myaccount.addressBook.cityFormatMsg":"Please don't include arabic numbers in city name.",
    "myaccount.addressBook.stateMsg":"Please enter your State/Province.",
    "myaccount.addressBook.zipMsg":"Please enter your ZIP/postal code with no more than 10 characters.",
    "myaccount.addressBook.zipFormatMsg":"Please enter Latin letters/numbers/spaces/hyphens only, and it must be less than 10 characters.",
    "myaccount.addressBook.faxMsg":"Please enter your mobile.",

    "myaccount.edit":"Edit",
    "myaccount.delete":"Delete",
    "myaccount.save":"Save",
    "myaccount.cancel":"Cancel",

    //myWishList
    "myWishList.increases.function":`My Wish List increases a new function of self-created sub wish lists to help easily classify and manage items.“{0} Wish List” is a default list where items already added can be found.`,
    "myWishList.myWishList":"My Wish List",
    "myWishList.items":"items",
    "myWishList.listName":"List Name",
    "myWishList.listDescription":"List Description",
    "myWishList.createList":"Create a List",

    //myWishListDetail
    "myWishListDetail.emptyList":"What’s a pity! This is an empty wish list.",
    "myWishListDetail.advancedSearch":"Advanced Search:",
    "myWishListDetail.addProductNow":"Add some product now",
    "myWishListDetail.category":"Category",
    "myWishListDetail.addDate":"Add Date",
    "myWishListDetail.codeOrName":"Item Code/Name:",
    "myWishListDetail.3day":"3 Days(Ready Time)",
    "myWishListDetail.reset":"Reset",
    "myWishListDetail.check":"Check",
    "myWishListDetail.to":"To",
    "myWishListDetail.item":"Item",
    "myWishListDetail.newest":"Newest",
    "myWishListDetail.price":"Price",
    "myWishListDetail.unavailable":"Unavailable",
    "myWishListDetail.add":"Add",
    "myWishListDetail.similarItems":"Similar Items",
    "myWishListDetail.deleteItem":"Delete Item",
    "myWishListDetail.moveToAnotherList":"Move to Another List",
    "myWishListDetail.emptyWishList":"Empty Wish List",
    "myWishListDetail.isConfirmDelete":"Do you confirm to delete it?",
    "myWishListDetail.pleaseEnteryourListName.":"Please enter your list name.",
    "myWishListDetail.listNameAlreadyExists":"Sorry! A list with this name already exists.",
    "myWishListDetail.allowOtherView":"Allow other view this list",

    // OnlyForYou
    "onlyForYou":'Only For You',

    //specialBargainsForYou
    "specialBargainsForYou":"Special Bargains for You",
    "specialBargainsForYou.advancedSearch":"Advanced Search:",
    "specialBargainsForYou.category":"Category:",
    "specialBargainsForYou.itemCode":"Item Code:",
    "specialBargainsForYou.discount":"Discount:",
    "specialBargainsForYou.3day":"3 Days(Ready Time)",
    "specialBargainsForYou.reset":"Reset",
    "specialBargainsForYou.check":"Check",
    "specialBargainsForYou.to":"To",
    "specialBargainsForYou.endDate":"End Date",
    "specialBargainsForYou.unavailable":"Unavailable",
    "specialBargainsForYou.add":"Add",
    "specialBargainsForYou.allCategories":"-- All Categories --",

    //cancelledItems
    "cancelledItems.cancelledItem":"Cancelled item",
    "cancelledItems.notice":"Notice:",
    "cancelledItems.showStockInfoOfPreviousOrders":`Just show you the real-time stock info of the cancelled item in your previous orders.If you still want them, please place order as soon as possible. You can select "in-stock Notice" for the unavailable item so that we can inform you in time when they are in stock again.`,
    "cancelledItems.advancedSearch":"Advanced Search:",
    "cancelledItems.category":"Category:",
    "cancelledItems.codeorName":"Item Code/Name:",
    "cancelledItems.inStock":"In Stock",
    "cancelledItems.reset":"Reset",
    "cancelledItems.check":"Check",
    "cancelledItems.to":"To",
    "cancelledItems.item":"item",
    "cancelledItems.stockInfo":"Stock Info",
    "cancelledItems.unavailable":"Unavailable",
    "cancelledItems.allCategories":"-- All Categories --",

    //purchaseHistory
    "purchaseHistory":"Purchase History",
    "purchaseHistory.advancedSearch":"Advanced Search:",
    "purchaseHistory.category":"Category:",
    "purchaseHistory.codeorName":"Item Code/Name:",
    "purchaseHistory.inStock":"In Stock",
    "purchaseHistory.reset":"Reset",
    "purchaseHistory.orderDate":"Order Date:",
    "purchaseHistory.check":"Check",
    "purchaseHistory.to":"To",
    "purchaseHistory.item":"Item",
    "purchaseHistory.lastOrderDate":"Last Order Date",
    "purchaseHistory.unavailable":"Unavailable",
    "purchaseHistory.addtoWishList":"Add to Wish List",
    "purchaseHistory.thereIsNoAnyPurchaseHistory":"There is no any purchase history.",
    "purchaseHistory.allCategories":"-- All Categories --",

    //onlyForYou
    "onlyforyou.allCategories":"-- All Categories --",
    "onlyforyou.advancedSearch":"Advanced Search:",
    "onlyforyou.category":"Category:",
    "onlyforyou.itemCode":"Item Code:",
    "onlyforyou.addDate":"Add Date",
    "onlyforyou.reset":"Reset",
    "onlyforyou.check":"Check",
    "onlyforyou.to":"To",
    "onlyforyou.add":"Add",

    //cashAccount
    "cashAccount.cashInfo":`When you have a credit in cash account, you can use it toward any orders with outstanding amount. In the payment step,
        our system will remind you to use the cash balance to pay, yes is the default choice.
        Just go ahead to complete the payment and your credit will be applied for your order.`,
    "cashAccount.balance":"Balance",
    "cashAccount.withdrawBalance":"Withdraw the balance by the original payment method",
    "cashAccount.depositForOrders":"Deposit for your orders in the future",
    "cashAccount.depositSuccessfullyTip":"Deposit successfully！We will update the Cash Account in 24 hours after you made the payment.",
    "cashAccount.receivedRefund":"We have received your refund request, our customer service team will arrange the refund soon.",
    "cashAccount.date":"Date",
    "cashAccount.income":" Income",
    "cashAccount.Payout":"Payout",
    "cashAccount.Description":"Description",

    //支付方式
    "payMethod.neverAskChangeBeneficiary2":"· In order to help us identify your payment more easily, please be sure to note your {0} registered email address when making remittance.",
    "payMethod.neverAskChangeBeneficiary3":"· <span style='color: #AF0F1E'>According to the banking requirements, we can only accept remittance from company accounts, not personal accounts.</span> If in doubt, please feel free to <a style='text-decoration: underline; color: #90bfb6;' target='_blank' href=\"/HelpCenter/HelpAsk\">contact us</a>.",
    "payMethod.neverAskChangeBeneficiary4":"· <span style='color: #AF0F1E'>According to the banking requirements, we can only accept remittance from company accounts, not personal accounts.</span> If in doubt, please feel free to <a href=\"/HelpCenter/HelpAsk\">contact us</a>.",
    "payMethod.neverAskChangeBeneficiary5":"· If in doubt, please feel free to <a style='text-decoration: underline; color: #90bfb6;' target='_blank' href=\"/HelpCenter/HelpAsk\">contact us</a>.",
    "payMethod.neverAskChangeBeneficiary6":"· If in doubt, please feel free to <a href=\"/HelpCenter/HelpAsk\">contact us</a>.",

    //coupon
    "coupon.couponCash": 'Coupon & Cash',
    "coupon.useCoupons":'Use Coupons:',
    "coupon.off":'OFF',
    //替代品
    "replacement.backToOrderDetails":"Back to Order Details",
    "replacement.selectReplacement":"Select Replacement",
    "replacement.pleaseSelectReplacementForOrder":" Please select replacement for order {0} from the following products.",
    "replacement.iHaveSelectedAllReplacements": "I have selected all replacements and want to checkout.",
    "replacement.viewAndCheckoutReplacement": "View & Checkout Replacement.",
    "replacement.shortOfStockSum": "Short-of-Stock Sum",
    "replacement.replacementTotal": "Replacement Total",
    "replacement.replacementPrice": "Replacement Price",
    "replacement.replacementRedemption": "Replacement Redemption",
    "replacement.pleaseSelectReplacementFirst": "Sorry, please select replacement first!",
    "replacement.clickHereForMoreDetails": "Please <a href=\"/HelpCenter/HelpDetail?articleId=121&amp;articleIndex=10\" class=\"red\">click here</a> for more details.",
    "replacement.shortOfStockSumIs": "The short-of-stock sum is",
    "replacement.extraDiscountTip":"We will offer an extra discount <span class=\"red\">{0}%</span> off for the replacement items totaling <span class=\"red\">{1}</span> which means double discounts (excluding the shipping cost).",
    "replacement.shippingWeight": "Shipping Weight",
    "replacement.packageSize": "Package Size",
    "replacement.packingWay": "Packing Way",
    "replacement.options": "Options",
    "replacement.readyTime": "Ready Time",
    "replacement.days":"Days",
    "replacement.itemsInTheSet": "Items in the Set",
    "replacement.relatedItems": "Related Items",
    "replacement.noProductTip":"Oops, seems there is no product found. You can try to find what you want in all <a style='color: #f94d5a;text-decoration: underline;' href=\"/Replacement/ShowStockProduct?orderId={0}\">stock items!</a>",
    "replacement.myShoppingCartItems": "My Shopping Cart Items",
    "replacement.findInMyWishList": "Find in My Wish List",
    "replacement.selectReplacementsFromWishList": "Select replacements from the instock items in your wish list",
    "replacement.findInStock": "Find in Stock",
    "replacement.tryToFindInStock": "Try to find what you want in all stock items",
    "replacement.selectSimilars": "Select Similars",
    "replacement.selectSimilarReplacementsForShort": "Select similar replacements for the short-of-stock items",
    "replacement.sortBy": "Sort By:",
    "replacement.sortAll": "All",
    "replacement.sortWholesale": "Wholesale",
    "replacement.sortSmallLot": "Small Lot",
    "replacement.myWishListItems": "My Wish List Items",
    "replacement.findInShoppingCart": "Find in My Shopping Cart",
    "replacement.selectFromCart": "Select Replacement from the instock items in your shopping cart",
    "replacement.tryFindStock": "Try to find what you want in all stock items",
    "replacement.shipReadyBackOrder": "Ship the ready and put the rest on back-order - {0}",
    "replacement.totalPaid": "Total Paid",
    "replacement.orderItemsPreview": "Order Items - Preview",
    "replacement.category": "Category",
    "replacement.allItems": "All Items",
    "replacement.readyItems": "Ready Items",
    "replacement.subtotal": "Subtotal",
    "replacement.description": "Description",
    "replacement.price": "Price",
    "replacement.qty": "Qty",
    "replacement.couponUnavailable": "Coupon unavailable",
    "replacement.code": "Code",
    "replacement.pkgSize": "Pkg Size",
    "replacement.orderQty": "Order Qty",
    "replacement.readyQty": "Ready Qty",
    "replacement.backOrderTotal": "The rest on back-order Total",
    "replacement.vat": "VAT",
    "replacement.shippingBackOrder": "Shipping & Handling Fee of back-order",
    "replacement.shippingReadyItems": "Shipping & Handling Fee of Ready Items",
    "replacement.additionalFee": "Additional Fee",
    "replacement.couponRedemption": "Coupon Redemption",
    "replacement.readyProductTotal": "Ready Product Total",
    "replacement.orderTotal": "Order Total",
    "replacement.placeOrder": "Place Order",
    "replacement.noItemsFound": "No Items Found",
    "replacement.itemCode": "Item Code",
    "replacement.productName": "Product Name",
    "replacement.unit": "Unit",
    "replacement.selectSimilarReplacements": "Select The Similar Replacements",
    "replacement.shippingMethod": "Shipping Method",
    "replacement.estimatedShippingTime": "Estimated Shipping Time",
    "replacement.shippingCost": "Shipping Cost",
    "replacement.withShippingRestrictionProduct": " with Shipping Restriction Product",
    "replacement.poBoxError": "{0} can't match the P.O. Box address! If you want to select one of them as a shipping method.",
    "replacement.shippingMethodNotSuitable": "The previous shipping method is not suitable. Please choose again.",
    "replacement.shippingWeightNotice": "Due to different packaging requirements and weight calculations of different freight companies, the final shipping weight will be different as well.",
    "replacement.replacementItems": "Replacement Items",
    "replacement.continueSelect": "Continue to select replacement",
    "replacement.shippingVia": "Via",
    "replacement.freeShipping": "Free Shipping",
    "replacement.shippingNotSuitable": "The previous shipping method is not suitable.",
    "replacement.chooseAgain": "Please choose again.",
    "replacement.freightRedemption": "Freight Redemption",


    "orderDetail.yourPrice":"Your Price:",
    "orderDetail.packageSize":"Package Size:",
    "orderDetail.shippingWeight":"Shipping Weight:",
    "orderDetail.orderQty":"Order Qty:",
    "orderDetail.itemUnavNow":"The item is unavailable now.",
    "orderDetail.productCategory":"Product Category:",
    "orderDetail.description":"Description:",
    "orderDetail.orderInformation":"Order Information",
    "orderDetail.orderNumber":"Order Number:",
    "orderDetail.orderDate":"Order Date:",
    "orderDetail.orderStatus":"Order Status:",
    "orderDetail.pageSnapshot":"This page is the <strong>Snapshot</strong> of the item in your order.",

    "orderDetail.orderBeenShipWith":"This order has been set to ship with",
    "orderDetail.orderDetails":"Order Details",
    "orderDetail.costForContactUs":"The cost for the cancelled item will be credited into your cash account in a few minutes. Please check your cash account for the detail later on.\n" +
        "              If you want a refund, please\n" +
        "              <a href=\"/HelpCenter/HelpAsk\" style=\"color: #42B6D3; text-decoration: underline;\">\n" +
        "                contact us\n" +
        "              </a>.",
    "orderDetail.keepWattingTip":"Sorry, some of your items in your order are unready temporarily, you can select the following choices to complete your order. If you don't like either, just keep waiting. We'll try our best to prepare all items for you.",
    "orderDetail.extraReplacementItems":"Extra {0} off discount for replacement items.",
    "orderDetail.selectStockItems":"Select what you want from our stock items.",
    "orderDetail.haveSelectedItems":"You have selected {0} item(s)",
    "orderDetail.justShipReady":"Just ship the ready, the unready will be canceled, back to wishlist and refund to cash account.",
    "orderDetail.shipReadyFirst":"Ship the ready first, the rest will be shipped when available without charging extra shipping fee.",
    "orderDetail.wantEveryOrder":"If you really want everything in the order, just keep waiting. We'll try our best to get your order fully prepared. Meanwhile, the above options are still available to you if you change your mind.",
    "orderDetail.chosenKeepWatting":"You have chosen to keep waiting. We will try our best to get your order fully prepared.",
    "orderDetail.clickReceiveOrder":"Click this button if you have received your order",
    "orderDetail.orderReadyRate":"Order Ready Rate:",
    "orderDetail.estimateDispatchDate":"Estimate Dispatch Date:",
    "orderDetail.productCost":"Product Cost:",
    "orderDetail.overweightCost":"Overweight Cost:",
    "orderDetail.handlingFee":"Handling Fee:",
    "orderDetail.shippingCostForFreeShipping":"Shipping Cost for Items Without Free Shipping:",
    "orderDetail.totalShippingCost":"Total Shipping Cost:",
    "orderDetail.additionalFee":"Additional Fee:",
    "orderDetail.gst":"GST:",
    "orderDetail.couponRedemption":"Coupon Redemption:",
    "orderDetail.replacementRedemption":"Replacement Redemption:",
    "orderDetail.specialOffer":"Special Offer:",
    "orderDetail.freightRedemption":"Freight Redemption:",
    "orderDetail.orderTotal":"Order Total",
    "orderDetail.totalPaid":"Total Paid",
    "orderDetail.paymentConfirming":"Payment Confirming",
    "orderDetail.withdrawOriginal":"Withdraw the balance by the original payment method",
    "orderDetail.refundShipment":"Your request to refund have been received, the refund will be arranged after shipment.",
    "orderDetail.creditReceived":"Your request has been received, we will credit the balance to your cash account after order finished.",
    "orderDetail.itemsOrdered":"Items Ordered",
    "orderDetail.makePayment":"Make Payment",
    "orderDetail.allItems":"All Items",
    "orderDetail.downloadAllImages":"Download All Images",
    "orderDetail.connectionError":"Connection error has occurred. Please try again later.",
    "orderDetail.itemCode1":"Item Code",
    "orderDetail.productName1":"Product Name",
    "orderDetail.pkgSize":"Pkg Size",
    "orderDetail.price":"Price",
    "orderDetail.orderQty1":"Order Qty",
    "orderDetail.readyQty":"Ready Qty",
    "orderDetail.subtotal":"Subtotal",
    "orderDetail.recipientEmail":"Recipient Email:",
    "orderDetail.download":"Download",
    "orderDetail.noFreeShipping":"NO FREE SHIPPING",
    "orderDetail.shareThoughts":"Share your thoughts with other customers and get cash points.",
    "orderDetail.repackingRequirement":"Repacking Requirement",

    "upsTracks.sorryTrackingInfoFailed": "Sorry, it's failed to appear the tracking information for some network bug.",
    "upsTracks.pleaseGoToOfficialSite": "Please go to",
    "upsTracks.orContactLocalNumber": "or contact the local number for details.",
    "upsTracks.trackingNo": "Tracking NO.: {0}",
    "upsTracks.logisticsCompany": "Logistics Company:",
    "upsTracks.destination": "Destination:",
    "upsTracks.timeZone": "Time Zone:",
    "upsTracks.deliveryStatus": "Delivery Status:",
    "upsTracks.shipmentTravelHistory": "Shipment Travel History",
    "upsTracks.date": "Date",
    "upsTracks.activity": "Activity",
    "upsTracks.location": "Location",
    "upsTracks.upsOfficial":"UPS official site",
    "upsTracks.localScanTime":"Local Scan Time",
    "upsTracks.noPackageInfoYet": "Sorry, no information for the shipment has been received by",
    "upsTracks.pleaseTryAgainOrContactLocal": "yet. Please try again later or contact the local number for details.",
    "upsTracks.failedToGetTrackingInfo": "Sorry, it's failed to appear the tracking information as there is incomplete information on",
    "upsTracks.pleaseContactLocalForDetails": "Please contact the local number for details.",

    "printOrderInvoice.download": "Download",
    "printOrderInvoice.print": "Print",
    "printOrderInvoice.invoiceNo": "Invoice No",
    "printOrderInvoice.companyName": "Company Name",
    "printOrderInvoice.sellersAddress": "Seller's Address",
    "printOrderInvoice.shippedTo": "Shipped To",
    "printOrderInvoice.billingTo": "Billing To",
    "printOrderInvoice.shippingMethod": "Shipping Method",
    "printOrderInvoice.orderDate": "Order Date",
    "printOrderInvoice.weight": "Weight",
    "printOrderInvoice.orderNo": "Order No",
    "printOrderInvoice.itemCode": "Item Code",
    "printOrderInvoice.productName": "Product Name",
    "printOrderInvoice.quantity": "Quantity",
    "printOrderInvoice.pkgSize": "Package Size",
    "printOrderInvoice.price": "Price",
    "printOrderInvoice.subTotal": "Subtotal",
    "printOrderInvoice.productCost": "Product Cost",
    "printOrderInvoice.downloadItemsInfo": "Download Item Information",
    "printOrderInvoice.selectFileFormat": "Select file format",
    "printOrderInvoice.saveAsExcel": "Save as Excel",
    "printOrderInvoice.saveAsCsv": "Save as CSV",

    "orderReivew.orderReview": "Order Review - {0}",
    "orderReivew.notice": "Notice",
    "orderReivew.pleaseRatePerformance": "Please kindly take a little time to rate our performance on this order, your feedback will help us to offer you better service. Thanks for your cooperation.",
    "orderReivew.productsQuality": "Products Quality",
    "orderReivew.gradeProductsQuality": "Please grade our products quality",
    "orderReivew.enterCommentOnQuality": "Enter your comment on product quality",
    "orderReivew.shipment": "Shipment",
    "orderReivew.gradeShipment": "Please grade our shipment",
    "orderReivew.enterCommentOnShipment": "Enter your comment on shipment",
    "orderReivew.customerService": "Customer Service",
    "orderReivew.optional": "Optional",
    "orderReivew.gradeCustomerService": "Please grade our customer service",
    "orderReivew.enterCommentOnCustomerService": "Enter your comment on customer service",
    "orderReivew.complainCustomerServiceStaff": "Complain The Customer Service Staff Of This Order To The Customer Service Manager",
    "orderReivew.ifComplainingOrder": "If you are only complaining about the order itself, rather than the working ability and attitude of your exclusive customer service, please do not check this option.",
    "orderReivew.otherComments": "Your other comments or suggestions",
    "orderReivew.enterOtherComments": "Enter other comments or suggestions",
    "orderReivew.submit": "Submit",

    "orderTrack.package": "Package {0}",
    "orderTrack.orderTracking": "Order Tracking",
    "orderTrack.logisticsCompany": "Logistics Company",
    "orderTrack.deliveryStatus": "Delivery Status",
    "orderTrack.transit": "transit",
    "orderTrack.delivered": "delivered",
    "orderTrack.destination": "Destination",
    "orderTrack.shipmentTravelHistory": "Shipment Travel History",
    "orderTrack.date": "Date",
    "orderTrack.location": "Location",
    "orderTrack.activity": "Activity",
    "orderTrack.noInformationReceived": "Sorry, no information for the shipment has been received by {0} yet. Please try again later or access the Check Url and enter your tracking NO. to trace your shipment.",

    "fedexTrack.sorryTrackingInfoFailed": "Sorry, it's failed to appear the tracking information for some network bug.",
    "fedexTrack.fedexOfficialSite": "Fedex official site",
    "fedexTrack.orContactLocalNumber": "or contact the local number for details.",
    "fedexTrack.trackingNo": "Tracking NO.",
    "fedexTrack.logisticsCompany": "Logistics Company",
    "fedexTrack.destination": "Destination",
    "fedexTrack.timeZone": "Time Zone",
    "fedexTrack.localScanTime": "Local Scan Time",
    "fedexTrack.deliveryStatus": "Delivery Status",
    "fedexTrack.signedForBy": "Signed for by",
    "fedexTrack.shipmentTravelHistory": "Shipment Travel History",
    "fedexTrack.date": "Date",
    "fedexTrack.activity": "Activity",
    "fedexTrack.location": "Location",
    "fedexTrack.details": "Details",
    "fedexTrack.sorryNoInfoReceived": "Sorry, no information for the shipment has been received by",
    "fedexTrack.pleaseTryAgainLater": "Please try again later or contact the local number for details.",
    "fedexTrack.sorryTrackingFailed": "Sorry, it's failed to appear the tracking information as there is incomplete information on",
    "fedexTrack.pleaseContactLocalNumber": "Please contact the local number for details.",

    // cart
    "packWindow.repackingRequirement": "Repacking Requirement",
    "packWindow.originalPacking": "Original Packing",
    "packWindow.qty": "QTY",
    "packWindow.packageSize": "Package Size",
    "packWindow.invalidInput": "Invalid input",
    "packWindow.cantExceed10": "Can't exceed 10",
    "packWindow.writeRepackingRequirement": "Please write down your Repacking Requirement",
    "packWindow.writeLetteringRequirement": "Please write down your lettering requirement",
    "packWindow.notSureQuantity": "Not sure the quantity per unit currently, will let you know the packing fee after order is prepared.",
    "packWindow.repackingServiceLink": "Click <a href='/HelpCenter/HelpCategory?categoryId=11' style='text-decoration:underline'>Repacking Service</a> to know our repacking price.",
    "packWindow.packingFee": "Packing fee",
    "packWindow.quantityOfPacks": "Quantity Of Packs",
    "packWindow.totalPackingFee": "Total Packing Fee",
    "packWindow.customPackageNote": "We can meet your needs for any specific package or custom project at a very competitive price.",
    "packWindow.repackingServiceTime": "Repacking Service Time: 2-3 working days. Order will be delayed for 2-3 working days if repacking service is required.",
    "packWindow.customizedProductsNote": "Customized products with processing service cannot be cancelled when your payment is confirmed by us within 24 hours.",
    "packWindow.contactUs": "Please contact us if you have any further questions.",
    "packWindow.save": "Save",

    "paypalError.communicationErrorOccurred": "Communication Error Occurred",
    "paypalError.somethingWasWrongConnectingPayPal": "Something was wrong when connecting with PayPal. It was unable to complete your request.",
    "paypalError.pleaseTryAgainLater": "Please try again later. Sorry for the inconvenience.",
    "paypalError.backToCartAfter": "It will be back to Shopping Cart page after {0} seconds or you can",
    "paypalError.orClickHereToGoBack": "click here to go back.",

    "priceLevel.discountOff": "{0}% off",
    "priceLevel.price": "Price",
    "priceLevel.superDiscountOff": "20% off",


    // checkout
    "checkProduct.allItems": "All Items",
    "checkProduct.regularItems": "Regular Items",
    "checkProduct.promotionItems": "Promotion Items",
    "checkProduct.smartBargainsItems": "Smart Bargains Items",
    "checkProduct.noFreeShipping": "NO FREE SHIPPING",
    "checkProduct.viewItemsDetails": "View {0} items details",
    "checkProduct.description": "Description",
    "checkProduct.price": "Price",
    "checkProduct.quantity": "Quantity",
    "checkProduct.total": "Total",
    "checkProduct.readyTime": "Ready Time: {0} Days",
    "checkProduct.repackingRequirement": "Repacking Requirement",
    "checkProduct.notSureQuantity": "Not sure the quantity per unit currently, will let you know the packing fee after order prepared. Click <a style='text-decoration: underline' href='/HelpCenter/HelpCategory?categoryId=11'>Repacking Service</a> to know our repacking price.",
    "checkProduct.packFee": "Pack",

    "invoiceComment.customersAreResponsibleForCustoms": "Customers are responsible for any customs duty or VAT incurred.",
    "invoiceComment.pleaseInputDeclaredValue": "If you have special requests, please input the declared value in the shipping invoice. The declared value for the shipment must be compliant.\n" +
        "If it is less than 50%, this may result in increased customs inspection rates and additional charges.",
    "invoiceComment.toEnsureSmoothCustoms": "To ensure smooth customs clearance, please have your\n" +
        "      <strong>KYC</strong> documents ready, such as a Passport, Aadhaar Card, Voter ID Card, or Driver's License, which can verify your identity and address. Please provide them to Indian customs when required.",
    "invoiceComment.orderExceedsCHF62":" If the declared value of your order exceeds CHF 62, VAT may apply at a rate of 8.1%. To avoid customs clearance delays or additional charges, please ensure you have a Swiss VAT account number. Click <a style=\"color:blue; text-decoration: underline;\" href=\"/HelpCenter/HelpDetail?articleId=148\">here</a> for more details.",
    "invoiceComment.increaseCustomsRateTip": "If the customs rate increases, we will not be liable for the additional fees.",
    "invoiceComment.pleaseWriteAustralianBusiness": " Please write your Australian Business Numbers which is needed for customs clearance. If you don't have one, just leave it blank.",
    "invoiceComment.wantToShip": "If you want to ship by DHL/FedEx/UPS, please write your CCIC NO.",
    "invoiceComment.whichIsRequired": "which is required for customs clearance.",
    "invoiceComment.leaveItBlank": "If you don't have one, just leave it blank.",
    "invoiceComment.pleaseWriteVatCode":"Please write down our VAT Code or ID Card Number. It is needed for customer clearance.",
    "invoiceComment.shipViaUps": "If you want to ship via UPS, please provide your ID Card Number, as it is required for customs clearance.",
    "invoiceComment.facilitateCustomsClearance":"To facilitate customs clearance, please provide your CAPS ID (Bermuda Importer Registration Number).",
    "invoiceComment.goodsValueToCustoms": "Goods value declared to customs",
    "invoiceComment.auAcceptsGoods":"Please Note: AUPOST accepts goods which are declared under 1000AUD only.",
    "invoiceComment.subtotalPercentage": "Corresponding Percentage of the Subtotal",
    "invoiceComment.totalValueToCustoms": "Total value declared to customs",
    "invoiceComment.400characters":"400 characters left",
    "invoiceComment.companyName": "Company Name",
    "invoiceComment.orderComments": "Order comments",
    "invoiceComment.abnRequired": "ABN is required for this shipment.",
    "invoiceComment.ccicRequired": "CCIC is required for this shipment.",
    "invoiceComment.ccicRequiredForCustoms": "This is required for customs clearance.",
    "invoiceComment.ccicOptional": "CCIC is optional.",
    "invoiceComment.vatCodeRequired": "VAT code is required for shipments to this country.",
    "invoiceComment.idCardRequiredForUps": "ID card number is required for UPS shipments to this country.",
    "invoiceComment.capsIdRequired": "CAPS ID is required for shipments to this country.",
    "invoiceComment.ccic": "CCIC",
    "invoiceComment.rfc": "RFC",
    "invoiceComment.capsId": "CAPS ID",
    "invoiceComment.abn": "ABN",
    "invoiceComment.vatCode": "VAT Code",
    "invoiceComment.idCardNumber": "ID Card Number",
    "invoiceComment.australianBusNum": "Australian Business Numbers",
    "invoiceComment.idCardNo": "ID Card No",
    "invoiceComment.pleaseEnterYourEori":"Please enter your EORI NO.. If your order is for commercial use, it will make customs clearance more smoothly.",
    "invoiceComment.or":"or",
    "invoiceComment.optional":"optional",
    "invoiceComment.eoriNo":"EORI No",
    "invoiceComment.providingTheVAT":"Please enter your VAT NO.. Providing the VAT number in advance can avoid extra costs during customs clearance.",

    "shippingDiscount.exclusiveShipping":"Exclusive Shipping Discounts",
    "shippingDiscount.notice":"Notice",
    "shippingDiscount.noticeText": "Surprise! You have been selected to enjoy the following JewelryAndFindings shipping discounts.",
    "shippingDiscount.autoSelect": "When you check out, our system will automatically select the largest shipping discount for you based on your order amount.",
    "shippingDiscount.timeLimited": "Please remember that all discounts are time-limited, please use them before the deadline.",
    "shippingDiscount.minimumOrderAmount": "Minimum Order Amount:",
    "shippingDiscount.effectiveUseTime": "Effective use time:",
    "shippingDiscount.applicableScope": "Applicable Scope:",
    "shippingDiscount.shippingMethod": "Shipping method:"



};

export default en;